<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        target-components-header

        div(v-for="item in list" @click="onPopup(item.id)" class="end-target-content")
          div(class="end-target-item")
            img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path")
            div(class="end-target-item-data")
              div ターゲット名
              div {{ item.name }}
            div(class="end-target-item-data")
              div 目標Pt
              div {{ item.target_point.toLocaleString() }}
            div(class="end-target-item-data")
              div 終了日時
              div {{ item.updated_at | moment("YYYY/MM/DD HH:mm") }}

      div(class="content-right")
        pr-banner
    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import TargetComponentsHeader from "../components/Header";
import { getTargetListS4 } from '@/api/target'

export default {
  name: "SettingTargetHistoryIndex",
  components: {
    TargetComponentsHeader
  },
  data() {
    return {
      list: undefined,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret = await getTargetListS4();
      this.list = ret.data.data;
    },
    onPopup(target_id) {
      let link = this.$router.resolve({ name: 'SettingTargetHistoryDetail', params: {
        target_id
      } })
      window.open(link.href,'_blank');
    },
  }
};
</script>
